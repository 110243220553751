.seminar-details-wrapper {
  @include display-flex-column-center;
  margin-top: 180px;
  width: 90%;
  margin-inline: auto;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
  z-index: 1;
  position: relative;
}

.seminar-details-container {
  @include display-flex-column-center;
  justify-content: flex-start;
  width: 100%;
  margin-inline: 25px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    padding-block: 30px;
  }
}

.seminar-details-video-container {
  @include display-flex-column-center;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.seminar-details-tags {
  margin-top: 20px;
  span {
    font-size: 0.9rem;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
}

.seminar-details-card-cover {
  position: relative;
  z-index: 3;
  width: 100%;
  aspect-ratio: 16/9;
  align-self: center;
  background: $primary-background-color;
  height: 100%;
  img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }
}

.seminar-details-card-cover-image {
  width: 100%;
  
  img {
    z-index: 1;
  }
}

.seminar-details-content {
  @include display-flex-column-center;
  align-items: flex-start;
  margin-block: 50px;
  gap: 25px;
  width: 100%;
}

.seminar-details-title-header {
  @include display-flex-column-center;
  align-items: flex-start;
  width: 100%;
  gap: 30px;
}

.seminar-details-title-container {
  @include display-flex-row-center;
  justify-content: space-between;
  width: 100%;
}

.seminar-details-title {
  @include card-title-text-style(32px, 22px);
    padding-bottom: 8px;
}

.seminar-details-date {
  @include date-text-style;
}

.seminar-details-technologies {
  margin-right: 0px;
}

.seminar-details-description {
  @include card-description-text-style;
}

.seminar-details-related-seminars {
  align-items: flex-start;
  gap: 25px;
  width: 100%;
}

.seminar-details-related-seminars-title {
  @include card-title-text-style(32px, 24px);
}

.related-seminars-wrapper {
  @include display-flex-column-center;
  margin: auto;
  position: relative;
  overflow: hidden;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;

    .horizontal-drag-scroll {
      padding: 0px;
      margin: 0px;
    }

    .seminar-container:first-of-type {
      margin-left: 0px;
    }

    .seminar-container:last-child {
      margin-right: 0px;
    }
  }
}

.related-seminars-container-desktop {
  @include hide-in-mobile;
  margin-bottom: 0;
  width: 100%;
}

.related-seminars-container-mobile {
  @include hide-in-desktop;
  width: 100%;
}

.seminar-details-video-wrapper {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  height: 100%;
}

.seminar-details-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertical-separator {
  display: inline-flex;
  background-color: $tertiary-color;
  height: 25px;
  width: 1px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
}

.seminar-details-group-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 10px;

    .vertical-separator {
      display: none;
    }

    .initiative-link {
      font-size: 12px !important;
      text-align: center;
      align-self: center;
      
      p {
        line-height: 12px;
      }     
    }
  }
}
