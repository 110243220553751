.wrapper-max-width {
  @include notch-padding;
  width: 100%;
  @media screen and (min-width: 768px) {
    max-width: $max-width-viewport;
    margin-inline: auto;
    position: relative;
  }
}

.bg-container {
  position: relative;
  height: fit-content;
  background: $primary-background-color;
  @include display-flex-column-center;
}

.img-container {
  z-index: 0;
  position: absolute;
}

.bg-gradient {
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left {
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.right {
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.top {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.bottom {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.top-left {
  top: 0;
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
}

.bottom-left {
  bottom: 0;
  left: 0;
}

.bottom-right {
  bottom: 0;
  left: 0;
}
