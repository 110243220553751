@import './videoPlayerModal', './videoThumbnail';

.player-container {
  position: relative;
  width: 100%;
  height: 100%;

  /** Styles added to make the video player always fill the container sizes */
  div {
    width: 100% !important;
    height: 100% !important;
  }
  video {
    width: 100% !important;
    height: 100% !important;
    max-width: 100vw;
    max-height: 100vh;
    object-fit: cover;
  }
  video::cue {
    font-size: 1.5em;
  }
}
