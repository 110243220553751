.latest-seminars-wrapper {
  @include display-flex-column-center;
  margin: auto;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    width: 100%;

    .horizontal-drag-scroll {
      padding: 0px;
      padding-bottom: 50px;
      margin: 0px;
    }

    .seminar-container:first-of-type {
      margin-left: 0px;
    }

    .content-card-container:first-of-type {
      margin-left: 15px;
    }

    .seminar-container:last-child {
      margin-right: 0px;
    }
  }
}

.latest-seminars-container {
  display: flex;
  width: 100%;
}

.latest-projects-button {
  margin-block: 20px;
}
