.cluster-marker {
  color: $marker-color;
  background: $marker-background;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pin-marker {
  background-color: $marker-background;
  padding: 5px;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.pin-marker::after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 86%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 18px solid $marker-background;
}
