.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 50px 10px;
  margin-inline: auto;
  margin-block: 25px;
  z-index: 1;
  width: 90%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }

  &.grid-columns-5-2 {
    grid-template-columns: repeat(5, 1fr);

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.grid-columns-4-2 {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.grid-columns-3-2 {
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.grid-columns-3-1 {
    grid-template-columns: repeat(3, minmax(100px, 1fr));

    @media (max-width: 850px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &.grid-columns-2-1 {
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &.grid-columns-2-0 {
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 768px) {
      display: none;
    }
  }
}
