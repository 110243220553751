.job-offer-wrapper {
  position: relative;
  max-width: $max-width-viewport;
  margin-top: 180px;
  margin-inline: auto;
  background-color: $primary-background-color;

  @media (max-width: 768px) {
    margin-block: 140px;
  }
}

.job-offer-header {
  @include title-text-style(54px, 40px);
  margin-top: 50px;
}

.job-offer-container {
  position: relative;
  @include display-flex-column-center;
  align-items: flex-start;
  z-index: 1;
  margin: 50px 20px;
  padding: 50px 100px;

  @media (max-width: 768px) {
    padding: 10px;
  }
}

.job-offer-about {
  @include display-flex-row-center;
  justify-content: space-between;
  width: 100%;
  margin-block: 25px;

  @media (max-width: 768px) {
    @include display-flex-column-center;
    align-items: flex-start;
    margin-block: 20px;
  }
}

.job-offer-title {
  @include subtitle-text-style(40px, 24px);
}

.job-offer-date {
  @include date-text-style;
}

.job-offer-technologies {
  margin-top: 25px;
}

.job-offer-description {
  @include card-description-text-style(20px, 16px);
  color: $primary-text-color;
  text-align: justify;

  @media (max-width: 768px) {
    ul {
      padding-inline-start: 10px;
    }
  }
}

.job-offer-details {
  @include display-flex-column-center;
  align-items: flex-start;
  gap: 50px;
  margin-top: 50px;
}

.job-offer-button-container {
  margin-block: 50px;
}

.job-offer-button {
  font-weight: 700;
  font-size: 18px;
  background-color: $primary-color;
  color: $tertiary-text-color;
  margin-top: 70px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    padding: 10px;
    margin-inline: auto;
  }

  &:hover {
    transform: scale(1.1);
  }
}
