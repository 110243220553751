.project-container {
  padding: 50px 20px;
  min-width: min(80%, 400px);
  width: 100%;

  @media (max-width: 768px) {
    padding: 0px;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
}

.project-card-title {
  @include display-flex-row-center;
  justify-content: space-between;
  width: 100%;
}
