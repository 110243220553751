.navbar-link-text {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 20px;
  padding: 18px 30px;
  gap: 15px;
  color: $tertiary-text-color;
  transition: all 0.4s ease-in-out;

  a {
    font-size: 1em;
    line-height: 23px;
    text-decoration: none;
    color: $tertiary-text-color;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1250px) {
    padding: 15px 24px;
    gap: 10px;
    font-size: 0.9em;
  }

  @media (max-width: 1100px) {
    padding: 10px 16px;
    gap: 5px;
  }

  @media (max-width: 950px) {
    flex-direction: column;
    justify-content: space-around;
    padding: 8px;
    gap: 0px;
    font-size: 8px;
  }
}

.active-link {
  @media (max-width: 950px) {
    background-color: rgba(210, 197, 218, 0.3);
    margin: 0;
  }
}

.navbar-link-icon {
  position: relative;
  opacity: 0.8;
  width: 25px;
  aspect-ratio: 1;

  @media (max-width: 650px) {
    width: 16px;
  }
}
