.button-container {
  @include display-flex-row-center;
  border: none;
  width: 150px;
  height: 50px;
  gap: 10px;
  font-weight: 700;
  font-size: 18px;
  border-radius: 50px;
  color: $tertiary-text-color;
  background-color: $primary-color;
  transform: scale(1);
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    padding: 10px;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  @media (max-width: 850px) {
    font-size: 16px;
    width: 120px;
  }
}

.button-label {
  font-weight: bold;
  white-space: nowrap;
}
