.category-card {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 250px;
  text-align: left;
  margin: auto;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  transition: all 0.2s ease-in-out;

  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    height: 400px;
  }
}

.category-card-content {
  width: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.category-flipcard {
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 10px $card-shadow-lighter;
  }
}

.category-card-header {
  @include display-flex-row-center;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    @include display-flex-column-center;
    gap: 10px;
  }
}

.category-custom-image-container {
  @include display-flex-column-center;
  position: relative;
  background-color: $primary-color;
  border-radius: 20px;
  padding: 25px;
  width: 60px;
  height: 60px;
}

.category-item-header {
  @include display-flex-column-center;
  align-items: flex-start;
  width: 70%;
  padding-bottom: 5px;

  @media (max-width: 768px) {
    width: auto;
    align-items: center;
  }
}

.category-item-title {
  @include card-title-text-style;
  text-align: left;
  padding-bottom: 5px;

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 20px;
  }
}

.category-item-subtitle {
  @include card-subtitle-text-style;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
}

.category-technologies-space {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  height: fit-content;
  margin-top: auto;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.category-technologies-divider {
  width: 50%;
  margin-inline: auto;

  .ant-divider {
    margin-block: 10px;
  }
}

.category-technologies {
  gap: 1em;
}

.category-item-description {
  @include card-description-text-style;
  text-align: left;
  font-weight: 350;
  margin: auto;
}

.category-tag {
  color: $secondary-text-color !important;
  padding: 3px;
  border-radius: 5px;
}
