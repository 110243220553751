.seminars-list-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100vh;
  margin-block: 100px;
  padding-bottom: 50px;

  @media (max-width: 768px) {
    margin-block: 50px;
  }
}

.seminars-skeleton-container {
  height: 90%;

  @media (max-width: 768px) {
    min-height: 400px;
  }
}

.seminar-grid {
  margin-top: 50px;
}
