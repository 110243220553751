.header-wrapper {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header-background-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.header-background {
  width: 100%;
  height: 1000px;
  transform: scale(5) translateY(-200px);

  @media (max-width: 1500px) {
    transform: scale(5) translateY(-14.5vw);
  }
}
