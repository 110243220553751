.legal-container {
  @include display-flex-column-center;
  z-index: 1;
  position: relative;
  padding-top: 100px;
  padding-inline: 100px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
    padding-top: 40px;
    padding-inline: 40px;
  }
}

.legal-text-container {
  @include card(50px);
  margin-bottom: 50px;
  min-width: 100%;
}

.legal-text {
  padding-inline: 50px;

  h1 {
    margin-top: 1em;
  }
  ul {
    margin-top: 1em;
    margin-bottom: 1em;
    list-style: disc outside none;
  }
  ul li {
    margin-left: 2em;
    display: list-item;
    text-align: -webkit-match-parent;
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.5em;
  }

  @media (max-width: 768px) {
    padding-inline: 10px;
    ul {
      padding-inline-start: 0.5em;
    }
  }
}
