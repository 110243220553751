.footer {
  z-index: 0;
  position: relative;
  margin-top: 300px;
  background-color: $primary-color;

  @media (max-width: 850px) {
    height: 800px;
    margin-top: 50px;
  }

  svg {
    position: absolute;
    bottom: calc(100% - 20px);
    max-height: 400px;
  }
}

.footer-info-container {
  @include display-flex-row-center;
  flex: 1;
  padding-top: 50px;

  @media (max-width: 850px) {
    flex-direction: column-reverse;
    padding-top: 30px;
  }
}

.footer-address-container {
  max-width: 500px;

  @media (max-width: 1000px) {
    max-width: 400px;
  }
  @media (max-width: 850px) {
    @include display-flex-column-center;
    margin-top: 40px;
  }
}

.footer-address-image {
  position: relative;
  height: 70px;

  @media (max-width: 850px) {
    width: 300px;
    margin-bottom: 10px;
  }
}

.footer-address-details {
  @include display-flex-column-center;
  width: 55%;
  margin-bottom: 30px;

  @media (max-width: 850px) {
    width: 60%;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.footer-address-text-style {
  color: $tertiary-text-color;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 157.4%;

  @media (max-width: 850px) {
    text-align: center;
    font-size: 16px;
  }
}

.footer-email-addresses {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer-email-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35px;
  height: 35px;
  position: relative;
}

.footer-email-address {
  color: $tertiary-text-color;
  font-weight: 400;
  font-size: 20px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.footer-sections-container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 5px 20px;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 300px;
  width: max-content;
  margin-block: 30px;

  @media (max-width: 850px) {
    width: 80%;
    align-items: center;
    gap: 5px 20px;
  }
}

.footer-sections-link-text {
  @include display-flex-row-center;
  gap: 10px;
  width: 100%;
  margin-block: 3px;
  padding: 5px;
  border-radius: 15px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  color: $tertiary-text-color;
  transition: all 0.3s ease-in-out;

  &.is-active {
    font-weight: 600;
    background-color: rgba(210, 197, 218, 0.314);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(210, 197, 218, 0.251);
  }

  @media (max-width: 850px) {
    width: 120px;
  }
}

.footer-legal-container {
  z-index: 1;
  display: flex;
  gap: 20px;
  align-items: flex-start;

  a {
    color: $light-white;
    text-decoration: none;
  }

  @media (max-width: 850px) {
    display: flex;
    padding: 2%;
    align-items: center;
  }
}
