.card-wrapper {
  position: relative;
  z-index: 1;
  margin-block: 50px;
  margin-inline: 20px;
  padding-block: 30px;
  padding-inline: 70px;
  border-radius: 85px;
  border: $card-border;
  background-color: $primary-background-color;
  box-shadow: $card-shadow-darker;

  @media (max-width: 768px) {
    padding-inline: 30px;
    border-radius: 30px;
  }
}

.card-message {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: $primary-text-color;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.no-results-card {
  max-width: 1040px;
  margin: auto;
}
