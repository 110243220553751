.video-thumbnail-container {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  align-self: center;
  overflow: hidden;

  img {
    z-index: 1;
    background-color: $primary-background-color;
  }

  video {
    transform: scale(0.99);
    border-radius: 20px;
  }
}
