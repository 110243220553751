.benefits-wrapper {
  position: relative;
}

.benefits-offers-grid {
  place-items: flex-start;
  justify-items: center;
  grid-gap: 70px 20px;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 40px;
  }
}

.benefits-container {
  @include display-flex-column-center;
  justify-content: flex-start;
  margin-inline: 10px;
}
