/* Colors */
$primary-color: rgb(24, 62, 158);
$secondary-color: rgb(66, 114, 174);
$tertiary-color: rgb(116, 151, 191);

$primary-background-color: white;
$secondary-background-color: rgba(0, 0, 0, 0.4);
$tertiary-background-color: rgba(227, 235, 246);

/* Text colors */
$primary-text-color: rgb(36, 35, 49);
$secondary-text-color: gray;
$tertiary-text-color: white;
$light-white: rgba(255, 255, 255, 0.5);

/* Navbar component */
$navbar-gradient: linear-gradient(
  270deg,
  adjust-color($primary-color, $alpha: -0.4) 0%,
  $secondary-color 100%
);
$navbar-submenu-gradient: linear-gradient(
  20deg,
  adjust-color($primary-color, $alpha: -0) 0%,
  adjust-color($secondary-color, $alpha: -0) 50%
);

/* Card component */
$card-background: rgba(245, 245, 245, 1);
$card-border: 1px solid rgba(215, 215, 215, 1);
$card-shadow-darker: 0px 4px 75px rgba(152, 152, 152, 0.2);
$card-shadow-lighter: rgba(179, 179, 179, 0.25);
$card-message-color: rgba(5, 74, 136, 1);

/* Map  */
$marker-background: white;
$marker-color: #414141;

/* Misc */
$max-width-viewport: 1234px;
$modal-background-gradient: linear-gradient(
  0deg,
  $primary-color 10%,
  rgba(255, 255, 255, 0.6) 140%
);

/* Font Sizes */
$title-desktop-size: 75px;
$title-mobile-size: 36px;
$subtitle-desktop-size: 50px;
$subtitle-mobile-size: 32px;
$description-desktop-size: 25px;
$description-mobile-size: 20px;
$date-desktop-size: 18px;
$date-mobile-size: 14px;
$card-title-desktop-size: 28px;
$card-title-mobile-size: 32px;
$card-subtitle-desktop-size: 19px;
$card-subtitle-mobile-size: 20px;
$card-description-desktop-size: 18px;
$card-description-mobile-size: 16px;

:export {
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  tertiaryColor: $tertiary-color;

  tertiaryBackgroundColor: $tertiary-background-color;
}
