.play-button {
  width: 100%;
  max-width: 100px;
  height: auto;
  aspect-ratio: 1/1;
  background: black;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  opacity: 0.5;
  
  &:hover {
    transform: translate(-50%, -50%) scale(1.2);
  }

  transition: all 0.3s ease-in-out;
}
