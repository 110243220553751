.initiatives-wrapper {
  position: relative;
}

.initiatives-content {
  @include display-flex-column-center;
  z-index: 1;
  margin-inline: 20px;
}

.initiatives-grid {
  place-items: center;
  justify-items: center;
  row-gap: 80px;
  column-gap: 30px;
  grid-auto-rows: 1fr;
}
