.form-fields-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;

  Input,
  Item {
    width: 30vw;
    max-width: 450px;
    height: 60px;
    border: 1px solid lightgray;
    border-radius: 20px;
  }

  :last-child {
    grid-column: span 2;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0;

    Input,
    Item {
      width: 70vw;
      height: 50px;
      border: 1px solid lightgray;
      border-radius: 20px;
    }
  }
}

.form-button-container {
  display: flex;
  justify-content: space-around;
  margin-block: 20px;

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
    margin-block: 10px;
  }
}

.form-button {
  height: 65px;
  margin-right: 0;
  padding-inline: 10px;
  font-size: 16px;
  background-color: $primary-color;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    background-color: $primary-color;
  }
}
