.header-overhaul-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 50px;
  margin-inline: 20px;

  &.subtitle {
    margin-top: 40px;
    margin-bottom: 0;
    @media (max-width: 768px) {
      margin-top: 60px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 100px;
  }
}

.header-overhaul-header {
  @include display-flex-column-center;
  max-width: 800px;
}

.header-overhaul-title-container {
  @include display-flex-row-center;
  gap: 10px;
  padding-block: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.header-overhaul-icon-container {
  position: relative;
  width: 111px;
  height: 111px;
}

.header-overhaul-title {
  @include display-flex-row-center;
  @include title-text-style;

  &.dark-background {
    color: $tertiary-text-color;
  }

  &.subtitle {
    @include subtitle-text-style;
  }
}

.header-overhaul-description {
  @include description-text-style;
  padding: 10px;

  @media (max-width: 768px) {
    padding-inline: 20px;
  }

  &.dark-background {
    color: $tertiary-text-color;
  }

  &.subtitle {
    color: $secondary-text-color;
  }
}

.header-overhaul-button-container {
  margin-top: 30px;
}

.header-overhaul-button {
  font-size: 18px;
  padding: 10px;
  font-weight: 700;
  background-color: $primary-color;
  color: $tertiary-text-color;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    padding: 10px;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.header-overhaul-filter-container {
  @include display-flex-row-center;
  width: 100%;
  max-width: 500px;
  gap: 10px;
  margin-top: 50px;
}
