.custom-carousel {
  margin-block: 50px;
}

.coverflow-carousel {
  .swipe {
    padding-block: 100px !important; //To override the padding from the swiper library

    @media (max-width: 768px) {
      padding-block: 50px !important; //To override the padding from the swiper library
    }
  }
}

.swiper {
  width: 100%;
  padding-block: 0px 40px !important; //To override the padding from the swiper library
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  aspect-ratio: 16/9;

  img {
    display: block;
    width: 100%;
  }
}

.coverflow-slide {
  min-width: 300px;
  max-width: 1000px;
  aspect-ratio: 4/3;
}

.swiper-pagination {
  bottom: -40px;
}
