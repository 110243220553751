.avatar {
  //Avoid selecting text or image
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
}

.ant-popover-inner-content {
  display: flex !important;
}

.avatar-padding {
  padding: 2px !important;
}
