.header-home-page-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  background: $primary-background-color;
  overflow: hidden;
}

.header-home-page-background-svg-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.header-home-page-background-svg {
  width: 100%;
  height: 100%;
  transform: scale(4) translate(200px, -80px);

  @media (max-width: 850px) {
    transform: scale(4) translate(20vw, -80px);
  }

  @media (max-width: 600px) {
    transform: scale(5) translate(20vw, -80px);
  }
}

.header-home-page-content-container {
  @include display-flex-row-center;
  z-index: 1;
  max-width: 1400px;
  gap: 50px;
  margin: 200px auto;
  margin-bottom: 100px;

  @media (max-width: 1100px) {
    @include display-flex-column-center;
    margin-top: 150px;
    margin-bottom: 50px;
    gap: 30px;
  }
}

.header-home-page-info-container {
  @include display-flex-column-center;
  align-items: flex-start;
  margin-inline: 50px;
  gap: 50px;

  @media (max-width: 1100px) {
    align-items: center;
    gap: 30px;
  }
}

.header-home-page-title {
  font-weight: 800;
  font-size: 55px;
  color: $primary-text-color;

  @media (max-width: 1100px) {
    text-align: center;
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }
}

.header-home-page-subtitle {
  font-weight: 400;
  font-size: 25px;
  color: gray;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.header-home-page-button {
  @include display-flex-row-center;
  gap: 15px;
  width: 200px;
  height: 75px;
  border-radius: 40px;
  font-size: 20px;
  border: none;

  @media (max-width: 768px) {
    font-size: 16px;
    width: 170px;
    height: 60px;
  }
}

.header-home-page-chart-container {
  margin-right: 20px;
  padding: 20px;
  background: $tertiary-background-color;
  border-radius: 50px;

  @media (max-width: 768px) {
    width: 90vw;
    margin: 0;
  }
}

.header-home-page-chart-title {
  margin-left: 10px;
  font-size: 25px;
  font-weight: 700;
}

.header-home-page-chart-subcontainer {
  @include display-flex-column-center;
  align-items: flex-start;
  padding: 20px;
  background-color: $primary-background-color;
  border-radius: 50px;

  @media (max-width: 1100px) {
    align-items: center;
  }
}

.header-home-page-chart {
  @include display-flex-row-center;
  @media (max-width: 768px) {
    @include display-flex-column-center;
  }
}

.header-home-page-chart-legend {
  @include display-flex-column-center;
  align-items: flex-start;
  width: 150px;
  gap: 10px;
  font-size: 18px;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}

.header-home-page-chart-legend-title {
  display: flex;
  gap: 5px;
  color: gray;
  font-weight: 500;
  white-space: nowrap;
}

.header-home-page-chart-legend-value {
  font-size: 25px;
  font-weight: 900;
}

.doughnut-chart {
  position: relative;
  width: 350px;
  height: 350px;
}
