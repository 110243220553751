.innovation-awards-wrapper {
  @include display-flex-column-center;
  padding-bottom: 20px;
}

.innovation-awards-image-container {
  max-width: 1000px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
}
