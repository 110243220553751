.who-we-are-wrapper {
  @include display-flex-row-center;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  margin-inline: 20px;
  z-index: 1;
  position: relative;

  @media (max-width: 768px) {
    @include display-flex-column-center;
  }
}

.who-we-are-header {
  margin-bottom: 10px;
  margin-top: 0 !important;

  @media (min-width: 768px) {
    margin-top: 10px !important;
    justify-content: flex-start;
  }
}

.who-we-are-description-container {
  @include card-description-text-style;
  text-align: justify;
  margin-inline: 10px;
  padding: 10px;
  width: 60%;

  @media (max-width: 768px) {
    width: auto;
  }
}
