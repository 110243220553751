.seminar-container {
  padding: 50px 20px;
  min-width: min(80%, 400px);

  @media (max-width: 768px) {
    padding: 0 10px;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
}
