.meet-the-team-container {
  @include display-flex-column-center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;

  @media (max-width: 768px) {
    padding-bottom: 50px;
  }
}

.team-container {
  @include display-flex-row-center;
  flex-wrap: wrap;
  align-items: center;
  gap: 50px;
  max-width: 1000px;
  margin: 50px 20px;

  @media (max-width: 768px) {
    gap: 10px;
    margin: 5px 10px;
  }
}
