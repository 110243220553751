.navbar {
  z-index: 3;
  position: sticky;
  @include notch-padding;
  -webkit-tap-highlight-color: transparent;

  transition: top 1s;
}

.navbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  max-width: 2000px;
  margin-inline: auto;
  height: 100px;
  cursor: pointer;

  @media (max-width: 950px) {
    justify-content: space-between;
    height: 76px;
  }
}

.navbar-logo-container {
  @include display-flex-row-center;
  gap: 5px;
}

.navbar-logo-image {
  position: relative;
  width: 94px;
  height: 68px;

  @media (max-width: 950px) {
    max-width: 225px;
    left: 5px;
  }
}

.navbar-logo-title {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-align: end;
  line-height: 22px;
  letter-spacing: 2px;

  :first-child {
    font-size: 30px;
    letter-spacing: normal;
  }

  @media (max-width: 768px) {
    font-size: 8px;
    line-height: 14px;

    :first-child {
      font-size: 22px;
    }
  }
}

.navbar-sections-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  border-radius: 25px;
  background: $navbar-gradient;
  margin-inline: 10px;
  z-index: 2;

  @media (max-width: 1000px) {
    gap: 2px;
  }

  @include hide-in-mobile(950px);
}

.navbar-slider {
  height: 100%;
  width: 150px;
  border-radius: 25px;
  background-color: rgba(210, 197, 218, 0.3);
  position: absolute;
  transition: all 0.3s ease-in-out;
  z-index: 0;
}
