.arrow-button{
    width: 50px;
    height: 50px;
    background: none;
    cursor: pointer;
    border: none;
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
    transform: scale(1);

    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) {
            transform: rotate(180deg);
    }}

    &:hover{
        transform: scale(1.2);
        opacity: 1;

        @media not all and (min-resolution:.001dpcm)
        { @supports (-webkit-appearance:none) {
                transform: rotate(180deg) scale(1.2);
        }}
    }
}

.left-arrow-button{
    transform: rotate(180deg);

    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) {
            transform: rotate(0deg);
    }}


    &:hover{
        transform: rotate(180deg) scale(1.2);

        @media not all and (min-resolution:.001dpcm)
        { @supports (-webkit-appearance:none) {
            transform: rotate(0deg) scale(1.2);
        }}
    }
}