.content-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 40px;
  background: $primary-background-color;
  width: 85%;
  height: 90%;
  max-width: 80vw;
  margin-inline: auto;
  padding: 30px 20px;
  box-shadow: 1px 15px 20px $card-shadow-lighter;
  backface-visibility: hidden;

  @media (max-width: 768px) {
    width: 80%;
    min-height: 400px;
    margin: 20px auto;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 2px 20px $card-shadow-darker;
  }

  transition: all 0.3s ease-in-out;
}

.gallery-card {
  width: min(80%, 350px);
}

.content-card-cover {
  width: 100% !important;
  aspect-ratio: 16/9 !important;
  height: fit-content;
  border-radius: 20px;
  overflow: hidden;
}

.content-card-date {
  font-size: 18px;
  color: $secondary-text-color;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.content-card-header {
  @include display-flex-row-center;
  justify-content: space-between;
  width: 100%;
}

.content-card-title {
  margin: 0;
  padding: 0;
  @include card-title-text-style;
}

.content-card-subtitle {
  font-size: 22px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.content-card-description {
  margin: 0;
  @include card-description-text-style;
}

.clamp-description {
  @include limit-text-lines;
  max-height: 85px;
}

.content-card-bottom {
  margin-top: auto;
}
