.horizontal-drag-scroll {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  padding: 5px;
  margin-inline: 15px;
  scrollbar-color: $secondary-background-color !important;
  @include show-scroll-bar;
  backface-visibility: hidden;

  &.hide-scrollbar-desktop {
    @media (min-width: 768px) {
      @include hide-scroll-bar;
    }
  }

  &.hide-scrollbar-mobile {
    @media (max-width: 768px) {
      @include hide-scroll-bar;
    }
  }

  &.include-gap {
    gap: 10px;
  }
}

.gradient-mask {
  @media (min-width: 768px) {
    mask-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 5%,
      rgba(255, 255, 255, 1) 95%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-mask-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 5%,
      rgba(255, 255, 255, 1) 95%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.scroll-left-button {
  position: absolute;
  top: 60%;
  left: 0px;
  z-index: 10;
  @media (max-width: $max-width-viewport) {
    left: 5px;
  }
}

.scroll-right-button {
  position: absolute;
  top: 60%;
  right: 0px;
  z-index: 10;
  @media (max-width: $max-width-viewport) {
    right: 5px;
  }
}
