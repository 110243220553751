.social-media-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;

  @media (max-width: 768px) {
    gap: 40px;
  }
}

.social-media-buttons-images {
  position: relative;
  width: 45px;
  height: 45px;
  margin-bottom: 50px;
}
