@mixin margin-block-card {
  margin-block: 10px;
}

.benefit-item-image-container {
  @include margin-block-card;
  position: relative;
  width: 116px;
  height: 116px;
}

.benefit-item-image {
  color: $primary-text-color;
}

.benefit-card-item {
  @include display-flex-column-center;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px;
  gap: 10px;
  height: 90%;
  background: $primary-background-color;
  border-radius: 40px;
  text-align: left;
  min-height: 200px;
  margin-inline: auto;

  @media (max-width: 1400px) {
    height: fit-content;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 1px 15px 23px $card-shadow-lighter;
  }

  transition: all 0.2s ease-in-out
}

.benefit-item-title {
  @include card-title-text-style;
  @include margin-block-card;
}

.benefit-item-description {
  @include limit-text-lines(5);
  @include margin-block-card;
  @include card-description-text-style;
  text-align: justify;
}
