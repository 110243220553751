.initiative-card {
  @include display-flex-column-center;
  gap: 10px;
  text-align: center;
  align-self: stretch;
  max-width: 400px;
  margin: 10px;
  padding: 20px;
  border: none;
  border-radius: 40px;
  box-shadow: 0px 5px 5px $card-shadow-lighter;
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 1px 15px 23px $card-shadow-lighter;
  }
  transition: all 0.3s ease;
}

.initiative-image-container {
  position: relative;
  width: 200px;
  height: 200px;
}

.initiative-title {
  @include card-title-text-style;
  margin-block: 10px;
}

.initiative-description {
  @include card-description-text-style;
  padding: 2%;
  @include limit-text-lines;
}

.initiative-link {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  margin-top: auto;

  &:hover {
    color: $secondary-color;
  }
}
