.cultures-card-container {
  @include display-flex-row-center;
  justify-content: space-between;
  padding: 20px;
  background: $primary-background-color;
  border-radius: 40px;

  @media (max-width: 1100px) {
    @include display-flex-column-center;
    padding-inline: 10px;
    padding-block: 20px;
  }

  &:hover {
    box-shadow: 0px 17px 44px $card-shadow-lighter;
    transform: scale(1.05);
  }

  transition: all 0.3s ease;
}

.cultures-card-images-container {
  padding: 25px;
  border-radius: 20px;
}

.cultures-card-images {
  position: relative;
  width: 80px;
  height: 80px;
}

.cultures-card-content-container {
  @include display-flex-column-center;
  align-items: flex-start;
  width: 300px;
  padding: 20px;
  gap: 10px;

  @media (max-width: 768px) {
    width: auto;
    align-items: center;
    text-align: center;
  }
}

.cultures-card-title {
  @include card-title-text-style;
}

.cultures-card-content {
  @include card-description-text-style;
}
