.flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin-block: 20px;
  perspective: 1000px;
  &.back-flip {
    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    background: $primary-background-color;
    border-radius: 40px;
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      @-moz-document url-prefix() {
        transform: rotateX(0deg);
      }
    }
    .flip-card-back {
      transform: rotateY(180deg);
    }
  }
}
