.error-container {
  @include display-flex-column-center;
  padding: 20px;
  height: 100vh;
  gap: 10px;
  z-index: 1;
  position: relative;
  text-align: center;
}

.error-title {
  font-size: 5rem;
  margin-bottom: 0px;
}

.error-text {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 40px;
  mix-blend-mode: exclusion;
}

.error-button {
  height: 50px;
  background: $primary-color;
  border-radius: 40px;
  font-size: 1rem;
}
