.hire-button {
  border-radius: 30px;
  color: $tertiary-text-color;
  background: $primary-color;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  transform: scale(1);

  &:hover {
    cursor: pointer;
    background: $secondary-color;
    transform: scale(1.1);
  }
}

.hire-icon {
  position: relative;
  width: 25px;
  height: 25px;
}

.hire-icon-world {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 1s ease-in-out;
  transform: scale(1) translate(0);
  opacity: 1;

  &.icon-transition {
    transform: scale(10) translate(22%, 2%);
    opacity: 0;
  }
}

.hire-wave {
  position: absolute;
  bottom: 5px;
  left: -4px;
  width: 100%;
  height: 100%;
  transition: all 1s ease-in-out;
  opacity: 0;
  transform: scale(0.1);

  &.icon-transition {
    bottom: 30px;
    left: -4px;
    opacity: 1;
    transform: scale(1);
  }
}

#wave-icon {
  text-align: center;
  font-size: 24px;

  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
