.transcriptions-container {
  display: flex;

  .ant-drawer-mask {
    position: relative !important;
    display: flex !important;
  }

  .ant-drawer-content-wrapper {
    position: relative !important;
    aspect-ratio: 16/9 !important;
    border-radius: 20px !important;
    margin-left: 30px !important;
  }

  .ant-drawer-content {
    position: relative !important;
    border-radius: 20px !important;
  }

  .ant-drawer-body {
    position: relative !important;
  }

  .ant-drawer {
    position: relative !important;
    display: flex !important;
  }
}

.transcription-modal-container {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100vh !important;
  max-height: 100vh !important;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  border-radius: 0px;

  .transcription-modal-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-left: 20px;
  }

  .transcription-modal-body {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
  }

  .modal-title {
    color: $primary-text-color;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .transcription-select-container {
    display: flex;
    margin-bottom: 18px;
    padding-right: 20px;
    padding-left: 20px;

    .ant-select {
      width: 100%;
    }
  }

  .ant-modal-content { 
    border-radius: 0px; 
    margin: 0px;
    padding: 0px;
    max-width: 100% !important;
    width: 100% !important;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

.transcriptions-header {
  .close-button {
    background-color: transparent;
    border-width: 0px;
    margin-left: 10px;
    cursor: pointer;
    height: 100%;
    box-shadow: none;
  }
}