.menu-button-image {
  @include hide-in-desktop(950px);
  color: $primary-background-color;
  stroke: $tertiary-text-color;
  width: 109px;
  height: 93px;
  stroke-width: 4px;
  stroke-linecap: round;
  margin-block: auto;
  margin-top: 10px;
  margin-right: 10px;
  z-index: 1;

  &.is-open {
    width: 38px;
    stroke-width: 1px;
    height: 38px;
    margin-top: 20px;
    margin-right: 20px;
  }
}
