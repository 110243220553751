.team-map-container {
  position: relative;
  margin: 5px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

  .mapboxgl-map {
    border-radius: 30px;
    overflow: hidden;
  }

  .mapboxgl-ctrl-logo {
    opacity: 0.4;
  }
}

.team-map-header {
  margin-top: 0;
}
