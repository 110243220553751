.fixed-height-container {
  @include display-flex-column-center;
  height: fit-content;
}

.fixed-height-content {
  overflow: hidden;
}

.fixed-heigh-mask {
  mask-image: linear-gradient(
    180,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  -webkit-mask-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.fixed-height-show {
  background: none;
  border: none;
  font: inherit;
  outline: inherit;

  align-self: flex-end;
  margin-right: 0;
  padding-inline: 10px;
  color: $primary-color;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  font-size: 1.1rem;

  &:hover {
    opacity: 1;
  }
}
