.projects-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  margin-block: 100px;
  padding-bottom: 6rem;
  margin-inline: auto;

  @media (max-width: 768px) {
    margin-block: 50px;
  }
}

.projects-skeleton-container {
  height: 90%;

  @media (max-width: 768px) {
    min-height: 400px;
  }
}

.project-grid {
  margin-top: 50px;
}
