$translateZ-job-offer: 40px;

.job-offer-card {
  width: 75%;
  margin: 0;
  padding: 40px;
  box-shadow: 0px 10px 12px $card-shadow-lighter;
  border-radius: 30px;
  align-items: flex-start;

  @media (max-width: 768px) {
    padding-block: 35px;
    height: auto;
  }

  &:hover {
    background-color: $primary-color;
    cursor: pointer;

    p {
      color: $tertiary-text-color;
    }

    span {
      color: $tertiary-text-color;
    }

    .job-offer-card-offer-button {
      color: $primary-text-color;
      background-color: $primary-background-color;
    }
  }

  transition: all 0.3s ease-in-out;
}

.job-offer-card-container {
  min-height: 110px;

  transform: translateZ($translateZ-job-offer);
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.job-offer-card-title-container {
  @include display-flex-column-center;
  align-items: flex-start;
  gap: 10px;
  min-height: 65px;
  transform: translateZ($translateZ-job-offer);

  @media (max-width: 768px) {
    @include display-flex-column-center;
    align-items: flex-start;
  }
}

.job-offer-card-offer-name {
  @include card-title-text-style(28px, 22px);
  color: $primary-text-color;

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
}

.job-offer-card-offer-subtitle {
  @include subtitle-text-style(16px, 12px);
  font-weight: 500;
}

.job-offer-card-offer-description {
  @include card-description-text-style(16px, 14px);
  max-height: 200px;
  margin-bottom: 40px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-top: 5px;
  }
}

.job-offer-bottom {
  @include display-flex-row-center;
  justify-content: space-between;
  transform: translateZ($translateZ-job-offer);
  transform-style: preserve-3d;
}
