.navbar-mobile {
  @include hide-in-desktop(950px);
  @include display-flex-row-center;
  justify-content: space-around;
  position: fixed;
  z-index: 0;
  bottom: 5px;
  left: 0;
  right: 0;
  margin-inline: auto;
  width: 95%;
  height: fit-content;
  border-radius: 20px;

  background: $navbar-submenu-gradient;
  transition: all 0.4s ease-out;
}

.navbar-popover-link {
  padding: 10px 20px !important;
}

.navbar-ellipsis {
  color: $tertiary-text-color;
  font-size: 16px;
}

/** Antd popover override styles **/
.ant-popover-inner {
  background: $navbar-submenu-gradient !important;
  align-items: center;
  margin-right: 5px;
  padding: 5px !important;
  border-radius: 20px !important;
}
.ant-popover-arrow {
  --antd-arrow-background-color: #35549e !important;
}
