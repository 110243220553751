.thumbnail-card-cover {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  align-self: center;
  margin-bottom: 20px;
  overflow: hidden;

  img {
    border-radius: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    height: 200px;
    aspect-ratio: auto;
  }
}

.thumbnail-tag-container {
  position: absolute;
  z-index: 2;
  display: flex;
  width: 100%;
  bottom: 10px;
  left: 0;
  gap: 10px;

  .thumbnail-card-tag:first-child {
    margin-left: 10px;
  }

  .thumbnail-card-tag:last-child {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    .thumbnail-card-tag:first-child {
      margin-left: 0px;
    }

    .thumbnail-card-tag:last-child {
      margin-right: 0px;
    }
  }
}

.thumbnail-card-tag {
  background-color: rgba(245, 245, 245, 0.9);
  padding: 10px 20px;
  margin-inline: 5px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  white-space: nowrap;
  margin-bottom: 10px;
}

.thumbnail-play-icon {
  position: absolute;
  z-index: 2;
  width: 20%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
}
