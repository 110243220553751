.scroll-to-top-button-container {
  @include notch-padding;
  z-index: 1001;
  position: fixed;
  right: 40px;

  @media (max-width: 768px) {
    right: 10px;
    transform: scale(0.6);
  }

  transition: bottom 0.7s;
}

.scroll-to-top-button {
  color: $primary-color;
  background-color: $tertiary-background-color !important;
  border-color: $primary-color;
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;

  span {
    font-size: 20px !important;
  }

  @media (max-width: 768px) {
    width: 70px !important;
    height: 70px !important;
    transform: translateY(15px);
  }

  &:hover {
    transform: scale(1.15);
  }

  transition: all 0.3s;
}
