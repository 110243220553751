.location-card-container {
  @include display-flex-column-center;
  width: min(70vw, 400px);
  height: 430px;
  margin-bottom: 50px;
  margin-inline: 30px;
  background: $tertiary-text-color;
  box-shadow: 1px 15px 23px $card-shadow-lighter;
  border-radius: 40px;

  @media (max-width: 768px) {
    height: 350px;
    margin-inline: auto;
    padding: 10px;
  }

  &:hover {
    transform: scale(1.05);
  }

  transition: all 0.3s ease;
}

.location-card-image {
  position: relative;
  width: 80px;
  height: 80px;
}

.location-card-city {
  margin-top: 10px;
  font-weight: 700;
  font-size: 25px;
}

.location-card-address {
  margin-block: 10px;
  text-align: center;
  max-width: 230px;
  font-weight: 400;
  font-size: 18px;
  color: $secondary-text-color;
}

.location-card-country {
  margin-top: 20px;
  padding: 20px 30px;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  box-shadow: 0px 17px 44px $card-shadow-darker;
  border-radius: 40px;
}
