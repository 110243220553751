.contact-wrapper {
  @include display-flex-column-center;
  z-index: 1;
  position: relative;
  padding-top: 100px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
    padding-top: 40px;
  }
}

.contact-cards {
  position: relative;
  display: flex;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
}

.contact-form {
  @include display-flex-row-center;
  max-width: $max-width-viewport;
  align-items: flex-start;
  margin-bottom: 100px;
  padding: 50px 100px;
  background: $primary-background-color;
  box-shadow: 0px 17px 44px $card-shadow-darker;
  border-radius: 40px;

  @media (max-width: 768px) {
    @include display-flex-column-center;
    width: stretch;
    margin-block: 50px;
    margin-inline: 20px;
    padding: 30px;
  }
}

.contact-location-cards {
  @include display-flex-row-center;
  margin-block: 100px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
