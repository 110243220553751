@mixin hide-in-desktop($breakpoint: 768px) {
  @media (min-width: $breakpoint) {
    display: none;
  }
}

@mixin hide-in-mobile($breakpoint: 768px) {
  @media (max-width: $breakpoint) {
    display: none;
  }
}

@mixin notch-padding {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
}

@mixin hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin display-flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@mixin display-flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin limit-text-lines($lines: 3) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
  -moz-box-orient: vertical;
  -moz-line-clamp: $lines;
  -ms-box-orient: vertical;
  -ms-line-clamp: $lines;
  line-clamp: $lines;
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}

@mixin title-text-style($size: $title-desktop-size, $size-mobile: $title-mobile-size) {
  font-weight: 800;
  font-size: $size;
  color: $primary-text-color;
  text-align: center;
  @media (max-width: 768px) {
    font-size: $size-mobile;
  }
}

@mixin subtitle-text-style(
  $size: $subtitle-desktop-size,
  $size-mobile: $subtitle-mobile-size
) {
  max-width: $max-width-viewport;
  font-weight: 800;
  font-size: $size;
  color: $primary-text-color;
  @media (max-width: 768px) {
    font-size: $size-mobile;
  }
}

@mixin date-text-style($size: $date-desktop-size, $size-mobile: $date-mobile-size) {
  max-width: $max-width-viewport;
  font-size: $size;
  color: $secondary-text-color;
  @media (max-width: 768px) {
    font-size: $size-mobile;
  }
}

@mixin description-text-style(
  $size: $description-desktop-size,
  $size-mobile: $description-mobile-size
) {
  font-weight: 400;
  font-size: $size;
  line-height: 155%;
  color: $secondary-text-color;
  text-align: center;
  @media (max-width: 768px) {
    font-size: $size-mobile;
  }
}

@mixin card($padding: 30px, $border-radius: 30px) {
  background-color: $primary-background-color;
  box-shadow: 0px 10px 12px $card-shadow-lighter;
  padding: $padding;
  border-radius: $border-radius;

  @media (max-width: 768px) {
    padding: $padding/2;
  }
}

@mixin card-title-text-style(
  $size: $card-title-desktop-size,
  $size-mobile: $card-title-mobile-size
) {
  font-weight: 700;
  font-size: $size;
  line-height: 110%;
  color: $primary-text-color;
  @media (max-width: 768px) {
    font-size: $size-mobile;
  }
}

@mixin card-subtitle-text-style(
  $size: $card-subtitle-desktop-size,
  $size-mobile: $card-subtitle-mobile-size
) {
  font-size: $size;
  line-height: 155%;
  color: $secondary-text-color;
  @media (max-width: 768px) {
    font-size: $size-mobile;
  }
}

@mixin card-description-text-style(
  $size: $card-description-desktop-size,
  $size-mobile: $card-description-mobile-size
) {
  font-weight: 400;
  font-size: $size;
  line-height: 155%;
  color: $secondary-text-color;
  @media (max-width: 768px) {
    font-size: $size-mobile;
  }
}

@mixin show-scroll-bar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    width: 10px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 5px;
    height: 80px;
    background-color: $secondary-background-color;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
