.filter-bar-wrapper {
  @include display-flex-column-center;
  @include card();

  width: 100%;
  max-width: 1000px;
  margin-top: 50px;
  gap: 20px;

  @media (max-width: 1100px) {
    padding-inline: 10px;
  }

  @media (max-width: 768px) {
    width: 95%;
    padding-block: 40px 30px;
  }
}

.filter-bar-container {
  @include display-flex-row-center;
  width: 100%;
  gap: 10px;

  @media (max-width: 1200px) {
    gap: 5px;
  }

  @media (max-width: 768px) {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

.filter-bar-container-primary {
  @media (max-width: 768px) {
    @include display-flex-column-center;
  }
}

.filters-container {
  @include display-flex-row-center;
  gap: 10px;

  .ant-select-selection-item {
    background: none !important;
    color: rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.filter-bar-input {
  border-radius: 50px !important;
  min-width: 200px;
  align-self: flex-end;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
  }
}

.filter-bar-selector {
  min-width: 130px;
  max-width: 200px;
  text-transform: capitalize;

  .ant-badge-count {
    transform: translate(50%, 0%) !important;
  }

  .ant-select-selection-search {    
    margin-inline-start: 8px !important;
  }

  .ant-select-selection-overflow-item-rest {
    position: absolute !important;
    width: 100% !important;
  }

  .max-tags-placeholder {
    width: 100% !important;
  }
}

.dropbtn {
  color: $primary-text-color;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 10px !important;
  padding: 0px !important;
  width: 40px !important;
  height: 40px !important;
}

.dropbtn-image-container {
  position: relative;
  margin: auto;
  width: 30px;
  height: 30px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown:hover {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: $tertiary-background-color;
}

.filter-bar-search-icon {
  @include display-flex-row-center;
  font-size: 22px;
}

.filter-bar-modal-wrapper .ant-modal-content {
  border-radius: 30px !important; //Override modal default border-radius

  .ant-modal-footer {
    @include display-flex-row-center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 50px;
  }
}

.filter-bar-header-container {
  @include display-flex-row-center;
  justify-content: space-between;
  width: 100%;
}

.filter-bar-modal-label {
  font-size: 18px;
  font-weight: 500;
  margin-block: 10px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.filter-bar-modal-selector {
  text-align: left;
}

.filter-bar-close-icon {
  align-self: flex-start;
  text-align: left;
  font-size: 15px;
  margin-top: 5px;
  flex: 1;
  &:hover {
    cursor: pointer;
  }
}

.filter-modal-title {
  font-size: 22px;
  text-align: center;
  flex: 1;
}

.filter-bar-button-modal {
  align-self: flex-start;
  text-align: right;
  font-size: 14px;
  flex: 1;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.primary-filter {
  width: 100%;
  min-width: 160px;
  text-transform: capitalize;
}

.filter-tags {
  @include hide-in-mobile;

  display: flex;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 10px;
  padding-inline: 20px;

  span {
    cursor: pointer;
    padding-block: 5px;
  }
}

.clear-button {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.hide-clear-button {
  visibility: hidden;
  opacity: 0;
}
