.individual-card-container {
  display: flex;
  flex-direction: column;
  min-width: 30%;

  @media (max-width: 768px) {
    width: 40%;
    margin: 0 auto;
    align-items: flex-start;
  }
}

.individual-portrait-container {
  position: relative;
  margin: auto;
  width: 250px;
  height: 375px;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &:hover {
    .individual-portrait {
      filter: saturate(20%);
    }
    .individual-bg {
      filter: saturate(20%);
    }
    .individual-phrase {
      opacity: 1;
      bottom: 40px;
    }
  }

  @media (max-width: 650px) {
    width: 100%;
    height: 400px;
  }

  @media (max-width: 500px) {
    width: 100%;
    height: 300px;
  }
}

.individual-portrait {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transition: all 0.3s ease-in-out;

  span {
    position: initial !important;
  }
}

.individual-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 250px;
  max-width: 100%;
  aspect-ratio: 1/1;
  border-radius: 20px;
  opacity: 1;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.individual-phrase {
  @include display-flex-column-center;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
  min-height: 50px;
  padding: 4px;
  border-radius: 5px;
  background-color: $secondary-background-color;
  transition: all 0.3s ease-out;
  
  text-align: center;
  font-style: italic;
  font-size: 20px;
  color: $tertiary-text-color;
  opacity: 0;

  @media (max-width: 768px) {
    font-size: 10px;
  }
}

.individual-data-labels {
  @include display-flex-column-center;
  text-align: center;
  width: 100%;
  margin-top: 15px;
  gap: 5px;

  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  min-height: 35px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.individual-name {
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 13px;
    font-weight: 900;
  }
}
