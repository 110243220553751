.dual-pictures-wrapper {
  @include display-flex-column-center;
  margin: auto;
  z-index: 1;
  position: relative;
  width: 100%;
}

.dual-pictures-header {
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
}

.dual-pictures-content {
  @include display-flex-column-center;
  margin-top: 100px;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
}

.dual-pictures-container {
  @include display-flex-row-center;
  align-items: flex-start;
  gap: 38px;
  width: 100%;
  max-width: 95vw;
}

.dual-pictures-image-container {
  position: relative;

  @media (max-width: 1000px) {
    max-width: 800px;
    max-height: 600px;
  }

  @media (max-width: 768px) {
    max-width: 600px;
    max-height: 400px;
  }

  &.hide-in-mobile {
    @include hide-in-mobile(1000px);
  }
}
