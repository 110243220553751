.contact-card-container {
  @include display-flex-row-center;
  justify-content: space-between;
  height: 100px;
  margin-inline: 20px;
  padding: 10px 30px;
  background: $primary-background-color;
  border-radius: 40px;

  @media (max-width: 768px) {
    padding: 20px 20px;
    flex-direction: column;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  transition: all 0.3s ease;
}

.contact-card-image-container {
  @include display-flex-row-center;
  gap: 10px;
}

.contact-card-images {
  position: relative;
  width: 80px;
  height: 80px;

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
}

.contact-card-title {
  margin-right: 30px;
  font-weight: 700;
  font-size: 20px;
  color: $primary-text-color;
}

.contact-card-content {
  @include card-description-text-style;
}

.contact-card-links {
  text-decoration: none;
}
