.grid-skeleton-container {
  display: flex;
  flex-direction: row;
  margin: auto;
  gap: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.skeleton-paragraph {
  ul > li {
    margin-block: 10px !important; // Override the default margin of the skeleton
  }
}

.skeleton-group {
  display: flex;
  flex-direction: row;

  & > :not(:first-child) {
    margin-inline-start: -10px;
  }
}

.detailed-avatar-skeleton {
  width: 200px;
  display: flex;
  align-items: center;
  gap: 10px;
}
