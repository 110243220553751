.what-we-do-wrapper {
  position: relative;
}

.what-we-do-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(400px, 1fr));
  gap: 20px;
  padding-block: 20px;

  margin-inline: auto;
  z-index: 1;
  text-align: center;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, minmax(400px, 1fr));
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }
}
