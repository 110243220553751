.our-culture-wrapper {
  position: relative;
  max-width: $max-width-viewport;
  background-color: $primary-background-color;

  @media (min-width: 768px) {
    margin: auto;
  }
}

.our-culture-custom-grid {
  justify-items: center;

  @media (max-width: 900px) {
    @include display-flex-column-center;
    padding: 10px;
  }
}
