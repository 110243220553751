.latest-projects-wrapper {
  @include display-flex-column-center;
  position: relative;
  overflow: hidden;
}

.latest-projects-container {
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;

    .horizontal-drag-scroll {
      padding: 0px;
      padding-bottom: 50px;
      margin: 0px;
    }

    .project-container:first-of-type {
      margin-left: 0px;
    }

    .content-card-container:first-of-type {
      margin-left: 15px;
    }

    .project-container:last-child {
      margin-right: 0px;
    }
  }
}

.latest-projects-button {
  margin-block: 20px;
}
