.detailed-avatar-container {
  @include display-flex-row-center;
  gap: 10px;
}

.avatar-details {
  @include display-flex-column-center;
  justify-content: flex-end;
  align-items: flex-start;
}

.avatar-title {
  @include card-title-text-style(18px, 14px);
  font-weight: 400;
}
.avatar-subtitle {
  @include card-subtitle-text-style(14px, 12px);
  line-height: normal;
}
