.job-offers-container {
  @include display-flex-column-center;
  justify-content: flex-start;
  margin-inline: 10px;
  margin-bottom: 100px;
}

.job-offers-grid {
  place-items: center;
  justify-items: center;

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
