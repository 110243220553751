.player-modal {
    position: absolute;
    z-index: 99998;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
    aspect-ratio: 16/9;
  
    video{
      max-height: 100vh;
    }
  
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
  
  .player-modal-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: 99997;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.2s ease-in-out;
    outline: none !important;
  
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }

  .player-close-button{
    position: absolute;
    background-color: $primary-background-color;
    color: $primary-color !important;
    margin: 20px;
    right: 0;
    transform: scale(1);
    transition: all 0.3s;

    &:hover {
    border-color: $primary-color !important;
        transform: scale(1.2);
    }
    @media (max-width: 768px) {
      z-index: 99999;
      top: 34%;
      margin: 10px;
    }
}