.latest-seminar-card-container {
  @include hide-in-mobile;
  position: relative;
  z-index: 1;
  display: flex;
  background: $primary-background-color;
  box-shadow: 1px 15px 20px $card-shadow-lighter;

  width: 90%;
  min-height: fit-content;
  margin-inline: auto;
  gap: 20px;
  margin-top: 50px;
  border-radius: 30px;
  padding-block: 30px;
  padding-inline: 20px;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 80%;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 1px 5px 20px 10px $card-shadow-lighter;
  }

  &:active {
    transform: scale(0.98);
  }

  transition: all 0.2s ease-in-out;
}

.latest-seminar-card-cover {
  width: 100%;
  min-width: 40%;
  height: 100% !important;
  margin-right: 10px;
  border-radius: 25px;
  align-self: flex-start !important;
  aspect-ratio: none !important;
}

.latest-seminar-card-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 30%;
  min-width: 400px;
  margin-inline: 10px;

  @media (max-width: 1000px) {
    width: 100%;
    min-width: 100%;
    margin-inline: 0px;
  }
}

.latest-seminar-card-title {
  font-weight: 800;
  font-size: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.latest-seminar-card-description {
  margin-block: 15px;
  font-size: 20px;
}

.latest-seminar-card-authors {
  display: flex;
  column-gap: 20px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: 5px;
}
