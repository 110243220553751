.life-in-celtiberian-wrapper {
  @include display-flex-column-center;
  position: relative;
  margin: 0;
  z-index: 1;
}

.life-in-celtiberian-content {
  width: 99vw;
}

.life-in-celtiberian-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}

.life-in-celtiberian-carousel {
  margin-block: 50px;
  height: 600px;

  @media (max-width: 768px) {
    height: 300px;
  }
}
