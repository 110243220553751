.transcription-text-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    font-size: 15px;

    .transcription-time-button {
        display: flex;
        margin-right: 10px;
        cursor: pointer;
        background-color: transparent;
        border-width: 0px;
        height: fit-content;
        justify-content: flex-start;
        box-shadow: none;
        padding: 0px;
        color: $secondary-text-color;
    }

    .transcription-time-text {
        margin: 0px;
    }
    
    .transcription-text {
        color: $primary-text-color;
        margin: 0px;
    }
}