.project-details-wrapper {
  @include display-flex-column-center;
  margin-top: 180px;
  width: 90%;
  margin-inline: auto;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
  z-index: 1;
  position: relative;
}

.project-details-carousel {
  max-width: 800px;
  margin-block: 80px;

  @media (max-width: 768px) {
    margin-block: 20px;
  }
}

.project-details-container {
  @include display-flex-column-center;
  align-items: flex-start;

  width: 100%;
  margin-inline: 25px;
  margin-block: 80px;
  gap: 40px;
}

.project-details-image-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 30px;
  overflow: hidden;
  background: $primary-background-color;
}

.project-details-header {
  @include display-flex-row-center;
  justify-content: space-between;
  width: 100%;
}

.project-details-title-container {
  @include display-flex-column-center;
  align-items: flex-start;
  gap: 5px;
}

.project-details-title {
  @include card-title-text-style(40px, 28px);
}

.project-details-date {
  @include date-text-style;
}

.project-technologies-container {
  @include display-flex-column-center;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 20px;

  @media (max-width: 768px) {
    align-self: flex-start;
  }

  .ant-tag {
    margin-inline-end: 0px;
  }
}

.project-details-technologies {
  align-self: flex-end;
}

.project-details-description {
  @include card-description-text-style;
}

.project-details-related-projects {
  align-items: flex-start;
  gap: 25px;
  width: 100%;
}

.project-details-tags {
  margin-top: 20px;
  span {
    font-size: 0.9rem;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
}

.project-details-related-projects-title {
  @include card-title-text-style(32px, 24px);
}

.related-projects-wrapper {
  @include display-flex-column-center;
  margin: auto;
  position: relative;
  overflow: hidden;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;

    .horizontal-drag-scroll {
      padding: 0px;
      margin: 0px;
    }

    .project-container:first-of-type {
      margin-left: 0px;
    }

    .project-container:last-child {
      margin-right: 0px;
    }
  }
}

.related-projects-container-desktop {
  @include hide-in-mobile;
  margin-bottom: 0;
  width: 100%;
}

.related-projects-container-mobile {
  @include hide-in-desktop;
  width: 100%;
}
